/* body {
    background: linear-gradient(to bottom,#0B0B0B #A5FF00, );
    margin: 0;
    font-family: Arial, sans-serif;
  }
   */

  /* styles.css */
body {
  background-color: #0B0B0B;
}
